@mixin button_iconIsLeft($background, $color) {
  background-color: $background;
  padding: rem(8) rem(22);
  color: $color;
  display: flex;
  border-radius: rem(100);
  font-size: rem(10);
  line-height: 1;
  @include font_1_bold;
  width: auto;
  align-items: center;
  transition: all ease 0.3s;
  border: rem(1) solid $black;
  @include small {
    padding: rem(17) rem(20);
    font-size: rem(12);
  }

  &:after {
    //width: rem(8);
    margin-left: rem(10);
    transform: rotate(180deg);
    content: url("../../../public/imgs/arrow.svg");
  }

  &:hover {
    background-color: $color;
    color: $background;
    transition: all ease 0.3s;
    background-color: $white;

    &::after {
      transform: rotate(180deg);
      content: url("../../../public/imgs/arrowBlackleft.svg");
    }
  }
}

@mixin button_iconIsRight($background, $color) {
  background-color: $background;
  padding: rem(8) rem(22);
  color: $color;
  display: flex;
  border-radius: rem(100);
  font-size: rem(10);
  @include font_1_bold;
  width: fit-content;
  align-items: center;
  transition: all ease 0.3s;
  border: rem(1) solid $black;

  &:before {
    width: rem(8);
    margin-right: rem(10);
    content: url("../../../public/imgs/arrow.svg");
  }

  &:hover {
    background-color: $color;
    color: $background;
    transition: all ease 0.3s;
    background-color: $white;

    &::before {
      content: url("../../../public/imgs/arrowBlackleft.svg");
    }
  }
}

@mixin button_iconRight($background, $color) {
  background-color: $background;
  padding: rem(17) rem(22);
  color: $color;
  display: flex;
  border-radius: rem(100);
  font-size: rem(12);
  @include font_1_bold;
  width: fit-content;
  align-items: center;
  transition: all ease 0.3s;
  border: rem(1) solid $black;
  line-height: 1;

  &::after {
    width: rem(8);
    margin-left: rem(10);
    content: url("../../../public/imgs/arrowBlackleft.svg");
    transform: rotate(180deg);
  }

  &:hover {
    background-color: $color;
    color: $background;
    transition: all ease 0.3s;
    border: rem(1) solid $background;

    &::after {
      content: url("../../../public/imgs/arrow.svg");
    }
  }
}
