@import "src/styles/utils.scss";
.btnFilters {
  @include large {
    background-color: $black;
    color: $white;
    width: 100%;
    padding: rem(24) rem(20);
    text-align: left;
    font-size: rem(14);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
      content: url("./../../../public/imgs/filters.svg");
    }
  }

  &.btnClose {
    &::after {
      content: url("./../../../public/imgs/close.svg");
    }
  }
}
.filtersWrapper {
  display: flex;

  @include large {
    display: none;
  }

  &.opened {
    @include small {
      display: block;
    }
  }

  .filtersBlock {
    max-height: rem(500);
    overflow-y: scroll;
    padding-right: rem(10);
    @include scrollBar($lightBlue);

    @include large {
      padding: rem(25);
      max-height: 100%;
    }

    @include large {
      background-color: $white;
    }

    label {
      @include font_1_bold();
      font-size: rem(12);
      line-height: rem(15);
      padding: rem(8) rem(15);
      border-radius: rem(50);
      max-width: rem(300);
      @include extraLarge {
        max-width: rem(200);
      }
      display: flex;
      align-items: center;
      width: fit-content;
      cursor: pointer;
      transition: all ease 0.3s;
      border: rem(1) solid transparent;

      &:hover {
        border: rem(1) solid $black;
      }

      input {
        width: 0;
      }

      &#selectAll {
        margin-bottom: rem(16);
      }
      &.selected {
        background-color: $black;
        color: $white;
        &#selectAll {
          cursor: auto;
        }
      }
    }
    ul {
      list-style: none;
      li {
        padding: rem(2) 0;
        margin-bottom: rem(2);

        label {
          position: relative;
          padding-left: rem(32);

          &::before {
            content: url("../../../public/imgs/unselectedMuseum.svg");
            position: absolute;
            left: rem(17);
          }
          &.selected::before {
            content: url("../../../public/imgs/selectedMuseum.svg");
          }
        }
      }
    }
  }
}

.btn {
  display: block;
}
