@font-face {
  font-family: "Roboto-Black";
  font-weight: 900;
  src: url("/fonts/Roboto-Black.woff") format("woff"),
    url("/fonts/Roboto-Black.otf") format("opentype"),
    url("../fonts/Roboto-Black.ttf") format("truetype");
}
@mixin font_1_black() {
  font-family: "Roboto-Black", sans-serif;
  font-weight: 900;
}

@font-face {
  font-family: "Roboto-Bold";
  font-weight: 700;
  src: url("/fonts/Roboto-Bold.woff") format("woff"),
    url("/fonts/Roboto-Bold.otf") format("opentype"),
    url("../fonts/Roboto-Bold.ttf") format("truetype");
}
@mixin font_1_bold() {
  font-family: "Roboto-Bold", sans-serif;
  font-weight: 700;
}

@font-face {
  font-family: "Roboto-Light";
  font-weight: 300;
  src: url("/fonts/Roboto-Light.woff") format("woff"),
    url("/fonts/Roboto-Light.otf") format("opentype"),
    url("../fonts/Roboto-Light.ttf") format("truetype");
}
@mixin font_1_light() {
  font-family: "Roboto-Light", sans-serif;
  font-weight: 300;
}

@font-face {
  font-family: "Roboto-Medium";
  font-weight: 500;
  src: url("/fonts/Roboto-Light.woff") format("woff"),
    url("/fonts/Roboto-Light.otf") format("opentype"),
    url("../fonts/Roboto-Medium.ttf") format("truetype");
}
@mixin font_1_medium() {
  font-family: "Roboto-Medium", sans-serif;
  font-weight: 500;
}

@font-face {
  font-family: "Roboto-Regular";
  font-weight: 400;
  src: url("/fonts/Roboto-Regular.woff") format("woff"),
    url("/fonts/Roboto-Regular.otf") format("opentype"),
    url("../fonts/Roboto-Regular.ttf") format("truetype");
}
@mixin font_1_regular() {
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 400;
}

@font-face {
  font-family: "RobotoCondensed-Bold";
  font-weight: 700;
  src: url("/fonts/RobotoCondensed-Bold.woff") format("woff"),
    url("/fonts/RobotoCondensed-Bold.otf") format("opentype"),
    url("../fonts/RobotoCondensed-Bold.ttf") format("truetype");
}
@mixin font_2_bold() {
  font-family: "RobotoCondensed-Bold", sans-serif;
  font-weight: 700;
}

@font-face {
  font-family: "RobotoCondensed-Regular";
  font-weight: 400;
  src: url("/fonts/RobotoCondensed-Regular.woff") format("woff"),
    url("/fonts/RobotoCondensed-Regular.otf") format("opentype"),
    url("../fonts/RobotoCondensed-Regular.ttf") format("truetype");
}
@mixin font_2_regular() {
  font-family: "RobotoCondensed-Regular", sans-serif;
  font-weight: 400;
}
