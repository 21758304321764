@import "src/styles/utils.scss";

.wrapper {
  text.contentText {
    pointer-events: none;
    transition: opacity 0.5s;
    @include font_2_bold();
    font-size: rem(14);
    opacity: 0;

    &.visible {
      opacity: 1;
    }
    &.hidden {
      opacity: 0;
    }
  }
  circle {
    cursor: pointer;
  }
  :global(#blocker) {
    user-select: none;
    circle {
      cursor: auto;
    }
  }

  .museumCount {
    @include font_2_bold();
    font-size: rem(42);
    fill: $black;
  }
  .museumCountLabel {
    @include font_2_regular();
    font-size: rem(16);
    fill: $black;
  }
  .circleLabel {
    @include font_2_bold();
    font-size: rem(14);
  }
}

.chartWrapper {
  position: relative;
}

.emptyMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: $white;
  @include font_1_regular();
  font-size: rem(16);
}
