@use "sass:math";

// $color1: #1d1d1b;
// $color2: #3b5a65;
// $color3: #4a707f;
// $color5: #dce7ec;
// $color6: #233e48;
// $color7: #b1bdc1;
// $color8: #e8dad0;
// $color9: #cbb7a8;
// $color10: #d5ae8f;
// $color11: #c89c79;
// $color12: #d09067;
// $color13: #4a707f;
// $color14: #6e8d99;
// $color15: #d7d7d7;
// $color16: #d03535;

$white: #ffffff;
$lightGrey: #e7e7e7;
$grey: #c0c0c0;
$darkGrey: #666666;
$black: #000000;

$lightBlue: #d7e1eb;

$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$black-weight: 900;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 960px;
$breakpoint-xl: 1140px;
$breakpoint-xxl: 1401px;
$breakpoint-sm-height: 300px;
$breakpoint-md-height: 400px;
$breakpoint-lg-height: 500px;
$breakpoint-xl-height: 600px;
$breakpoint-xxl-height: 1000px;

$height-hp: 800px;

:export {
  /* stylelint-disable property-no-unknown */
  breakpointLG: math.div($breakpoint-lg, $breakpoint-lg * 0 + 1);
  breakpointMD: math.div($breakpoint-md, $breakpoint-md * 0 + 1);
  breakpointSM: math.div($breakpoint-sm, $breakpoint-sm * 0 + 1);
  breakpointXL: math.div($breakpoint-xl, $breakpoint-xl * 0 + 1);
  /* stylelint-enable */
}

$browser-context: 16; // Default

@function rem($pixels, $context: $browser-context) {
  @return #{math.div($pixels, $context)}rem;
}
