@import "./utils.scss";
@import "./reset.scss";

button {
  background: none;
  border: none;
}

.MuiPaper-root {
  border-radius: rem(10) !important;
  @include small {
    border-radius: 0 !important;
  }
  max-width: rem(690) !important;
  width: rem(690);
  overflow-y: hidden;
}

.MuiBackdrop-root {
  background-color: rgba(215, 225, 235, 0.8) !important;
}
