@import "./../../styles/utils.scss";

.wrapper {
  display: flex;
  border-radius: rem(10);

  & > button {
    align-self: end;
  }

  @include small {
    flex-direction: column;
    padding: rem(20) rem(30);
    border-radius: 0;
  }

  .rightContainer,
  .leftContainer {
    width: 50%;
    @include small {
      width: 100%;
    }
  }
}

.closeBtn {
  width: rem(40);
  height: rem(40);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: rem(-12);
}

.leftContainer {
  position: relative;
  .typeWrapper {
    display: flex;
    padding: rem(8) rem(20);
    align-items: center;
    justify-content: center;

    @include small {
      border-radius: rem(10) rem(10) 0 0;
      margin-top: rem(20);
      padding: rem(15) rem(20);
    }

    img {
      margin-right: rem(10);
    }
    .type {
      @include font_2_bold;
      font-size: rem(14);
      @include small {
        font-size: rem(16);
      }
    }
  }

  .img {
    height: rem(340);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e7e7e7;
    @include small {
      border: 1px solid #e7e7e7;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .defaultImg {
      width: rem(62);
    }
  }
}

.rightContainer {
  padding: rem(20) rem(30);
  @include small {
    padding: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      font-size: rem(16);
      margin-right: rem(4);
      @include font_1_black;
      margin-top: rem(10);
      margin-bottom: rem(8);

      &::first-letter {
        text-transform: capitalize;
      }

      @include small {
        font-size: rem(20);
        margin: 0;
        margin-top: rem(30);
      }
    }
  }
}

.goToContent {
  display: block;
  position: absolute;
  bottom: rem(32);
  left: 50%;
  transform: translateX(-50%);
  @include button_iconIsLeft($black, $white);
}

.description {
  @include font_1_light;
  font-size: rem(12);
  line-height: 1.4;
  padding-right: rem(36);
  max-height: rem(260);
  overflow-y: scroll;
  @include scrollBar($white);
  @include small {
    max-height: none;
    padding: 0;
    font-size: rem(14);
    margin-top: rem(12);
  }
}
.infosContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 0 rem(-4);
  justify-content: space-between;

  > div {
    margin-bottom: rem(10);

    span {
      @include font_2_regular;
      font-size: rem(12);
      @include small {
        font-size: rem(16);
      }
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.museumContainer {
  flex-basis: 100%;
  display: flex;
  padding: 0 rem(4);
}

.museumsList {
  overflow-y: auto;
  @include scrollBar($white);
  padding: rem(10) 0;

  li {
    list-style: none;
    font-size: rem(12);
    margin-bottom: rem(10);
    @include font_2_regular;
    @include small {
      font-size: rem(14);
    }
  }
}
.museums {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  border-top: rem(1) solid $lightGrey;
  padding-top: rem(10);
  margin-top: rem(15);
}
button.museums {
  border-bottom: rem(1) solid $lightGrey;
  width: 100%;
  padding-bottom: rem(10);
}
.langue,
.tarif,
.mode,
.lieu,
.duree,
.playlist {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  padding: 0 rem(4);
}

.icon {
  flex-shrink: 0;
  margin-right: rem(10);
}

.dropdownIconRotated,
.dropdownIcon {
  margin-left: auto;
  transform: rotate(180deg);
}
.dropdownIconRotated {
  transform: rotate(0deg);
}
