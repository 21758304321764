@mixin scrollBar($bg) {
  scrollbar-width: thin;
  scrollbar-color: #7f7f7f transparent;
  //scrollbar-gutter: stable both-edges;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    // border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #7f7f7f;
    border-radius: 10px;
    border: 3px solid $bg;
    -webkit-border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #6d6d6d;
    width: 10px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}
