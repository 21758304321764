@import "src/styles/utils.scss";

.header {
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
  width: 100%;
  .wrapper {
    padding: rem(11) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: rem(1024);
    margin: 0 auto;

    @include isExtraExtraLarge {
      max-width: rem(1140);
    }
    @include extraLarge {
      max-width: rem(900);
    }

    @include large {
      padding: rem(12) rem(20);
    }

    img {
      @include large {
        width: rem(44);
      }
    }
  }
}

.backTowebsite {
  font-size: rem(16);
  display: flex;
  align-items: center;
  line-height: 1;
  transition: all ease 0.3s;
  @include font_1_regular();

  @include large {
    font-size: rem(14);
  }

  &::before {
    content: url("./../../../public/imgs/arrowLeft.svg");
    display: block;
    margin-right: rem(8);
    transition: all ease 0.3s;
  }

  &:hover {
    text-decoration: underline;
    transition: all ease 0.3s;

    &::before {
      transform: translateX(-2px);
      transition: all ease 0.3s;
    }
  }
}
