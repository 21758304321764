@import "src/styles/utils.scss";

.info {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.98);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $white;
  z-index: 2;

  &.hide {
    display: none;
  }

  &.show {
    display: flex;
  }

  img {
    width: rem(60);
  }

  p {
    max-width: 80%;
    text-align: center;
    margin: rem(20) 0 rem(25) 0;
    @include font_2_regular();
  }

  button {
    @include button_iconRight($white, $black);
  }
}
.wrapper {
  min-height: calc(100vh - 92px);
  background: #d7e1eb;

  .container {
    display: flex;
    justify-content: space-between;
    max-width: rem(1024);
    padding: rem(60) 0;
    margin: 0 auto;
    min-height: calc(100vh - 92px);
    @include extraLarge {
      max-width: rem(900);
    }

    @include large {
      padding: 0;
      max-width: 100%;
    }

    @include isExtraExtraLarge {
      max-width: rem(1140);
    }
    @include large {
      flex-direction: column;
    }

    .filtersContainer {
      @include large {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .containerChart {
      position: relative;
      button {
        position: absolute;
        top: rem(50);
        z-index: 1;
        left: rem(-70);
        @include large {
          top: 0;
          left: rem(20);
        }
      }
    }
  }

  h1 {
    font-size: rem(22);
    font-weight: 300;
    margin-bottom: rem(30);

    @include large {
      margin-top: rem(28);
      padding: 0 rem(20);
    }
    span {
      display: block;
      font-weight: 500;
    }
  }
}
