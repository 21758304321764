@mixin small {
  @media (max-width: $breakpoint-sm - 1px),
    (max-height: $breakpoint-sm-height - 1px) {
    @content;
  }
}

@mixin medium {
  @media (max-width: $breakpoint-md - 1px),
    (max-height: $breakpoint-md-height - 1px) {
    @content;
  }
}

@mixin large {
  @media (max-width: $breakpoint-lg - 1px),
    (max-height: $breakpoint-lg-height - 1px) {
    @content;
  }
}

@mixin extraLarge {
  @media (max-width: $breakpoint-xl - 1px),
    (max-height: $breakpoint-xl-height - 1px) {
    @content;
  }
}

@mixin extraExtraLarge {
  @media (max-width: $breakpoint-xxl - 1px),
    (max-height: $breakpoint-xxl-height - 1px) {
    @content;
  }
}
@mixin isSmall {
  @media (min-width: $breakpoint-sm) and (min-height: $breakpoint-sm-height) {
    @content;
  }
}

@mixin isMedium {
  @media (min-width: $breakpoint-md) and (min-height: $breakpoint-md-height) {
    @content;
  }
}

@mixin isLarge {
  @media (min-width: $breakpoint-lg) and (min-height: $breakpoint-lg-height) {
    @content;
  }
}

@mixin isExtraLarge {
  @media (min-width: $breakpoint-xl) and (min-height: $breakpoint-xl-height) {
    @content;
  }
}

@mixin isExtraExtraLarge {
  @media (min-width: $breakpoint-xxl) {
    @content;
  }
}
