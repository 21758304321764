// RESET

* {
  box-sizing: border-box;
}

html {
  background: #ffffff;
  color: #000000;
}

body {
  -webkit-font-smoothing: antialiased;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: 400;
}
em {
  font-style: italic;
  font-weight: 400;
}

hr {
  margin: 0;
}

ul {
  //list-style: none;
  list-style-position: inside;
}
ol {
  list-style: decimal;
  list-style-position: inside;
}

a {
  color: $black;
  cursor: pointer;
  text-decoration: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 400;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
  font-variant: normal;
}
sub {
  vertical-align: text-bottom;
}
figure {
  margin: 0;
}
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;

  font-weight: inherit;
}

input {
  appearance: none;
  border-radius: 0;
}
legend {
  display: table;
  width: 100%;
  padding: 0;
  margin: 0;
  color: #000000;
  float: left;

  + * {
    clear: both;
  }
}

button {
  display: inline-block;
  border: none;
  margin: 0;
  background-color: $white;
  cursor: pointer;
  text-decoration: none;
}

input,
label,
select,
button,
textarea {
  display: inline-block;
  padding: 0;
  border: 0;
  margin: 0;
  background: none;

  /* Browsers have different default form fonts */
  line-height: 1;
  //white-space: normal;
  vertical-align: middle;
  //border-bottom: 1px solid $white;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
  &::placeholder {
    opacity: 0.6;
    transition: 0.3s;
  }
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
  box-sizing: border-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type="reset"],
input[type="button"],
input[type="submit"],
input[type="checkbox"],
input[type="radio"],
select {
  box-sizing: border-box;
}

/* Button Controls
-----------------------------------------------*/

input[type="checkbox"],
input[type="radio"] {
  width: 13px;
  height: 13px;
}

/* File Uploads
-----------------------------------------------*/

input[type="file"] {
  //empty
}

/* Search Input
-----------------------------------------------*/

/* Make webkit render the search input like a normal text field */
input[type="search"] {
  box-sizing: content-box;
  appearance: textfield;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* Buttons
-----------------------------------------------*/

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
  /* Fix IE7 display bug */
  overflow: visible;
  width: auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none;
}

/* Textarea
-----------------------------------------------*/

textarea {
  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
  /* Move the label to the top */
  vertical-align: top;
}

/* Selects
-----------------------------------------------*/

select[multiple] {
  /* Move the label to the top */
  vertical-align: top;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-size: initial !important;
}

input:is([type="button"], [type="submit"], [type="reset"]),
input[type="file"]::file-selector-button,
button {
  color: initial;
}

/* LEAFLET
-----------------------------------------------*/

.leaflet-tooltip {
  padding: 0 !important;
  border: none !important;
  opacity: 1 !important;
  white-space: unset !important;
}

fieldset {
  min-width: 0;
  padding: 0.01em 0 0 0;
  border: 0;
  margin: 0;
}
